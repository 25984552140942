import React, { Component } from "react";

import TeamCard from "./TeamCard";

export class Team extends Component {



  render() {
    return (
<TeamCard/>
    )
  }
}
