export const ADD_ARTICLE = "ADD_ARTICLE";
export const SET_RADIO_NAME = "SET_RADIO_NAME";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_SCHEDULE_MONDAY = "SET_SCHEDULE_MONDAY";
export const SET_SCHEDULE_TUESDAY = "SET_SCHEDULE_TUESDAY";
export const SET_SCHEDULE_WEDNESDAY = "SET_SCHEDULE_WEDNESDAY";
export const SET_SCHEDULE_THURSDAY = "SET_SCHEDULE_THURSDAY";
export const SET_SCHEDULE_FRIDAY = "SET_SCHEDULE_FRIDAY";
export const SET_SCHEDULE_SATURDAY = "SET_SCHEDULE_SATURDAY";
export const SET_SCHEDULE_SUNDAY = "SET_SCHEDULE_SUNDAY";
export const SET_POLL_QUESTION = "SET_POLL_QUESTION";
export const GET_POLL_RESULTS = "GET_POLL_RESULTS";
export const PUT_POLL_RESULTS = "PUT_POLL_RESULTS";
export const GET_US_NEWS = "GET_US_NEWS";
export const SET_TEAM = "SET_TEAM";
export const SET_CURRENT_SHOW = "SET_CURRENT_SHOW";
export const URL = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:5000/api/";
export const MEDIAURL = process.env.NODE_ENV === 'production' ? "/mediakit" : "http://localhost:5000/mediakit";